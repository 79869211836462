import React, { Component } from "react"
import styled from 'styled-components'
import DisneyFooter from "./NewDisneyFooter"

const FooterStyled = styled.footer`
    background-color: white;
    width: 100%;
    position: relative;
`

class Footer extends Component {

    render() {
        return (
            <FooterStyled className="footer">
                <DisneyFooter />
            </FooterStyled>
        )
    }
}
export default Footer