import React, { useState } from 'react'
import styled from 'styled-components'
import Hamburger from './Hamburger'
import DisneyLogo from './DisneyLogo'
import MobileMenu from './MobileMenu'
import Navigation from './Navigation'


const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  z-index: 99;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    width: 90%;
    margin: 0 auto;
    padding: 1rem 0;
    .nav-wrapper {
      @media (min-width: 992px) {
        display: flex;
        align-items: center;
        gap: 30px;
        ul {
          display: flex;
          gap: 1.5rem;
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            font-weight: 600;
            text-transform: uppercase;
            font-family: "proxima-nova", sans-serif;
          }
        }
      }
      .logo {
        margin-left: -30px;
        svg {
          width: auto;
          height: 40px;
        }
        @media (min-width: 992px) {
          margin: 0;
          svg {
            height: 50px;
          }
        }
      }
      .navigation {
        display: none;
        @media (min-width: 992px) {
          display: block;
        }
      }
    }
  }
`
export default function NewHeader() {
  const [open, setOpen] = useState(false)
  return (
    <>
      <StyledHeader id="header" open={open}>
        <div className="header">
          <Hamburger open={open} setOpen={setOpen} />
          <div className="nav-wrapper">
            <div className="logo">
              <a href="/">
                <DisneyLogo />
              </a>
            </div>
            <div className="navigation">
              <Navigation
                open={open}
                setOpen={setOpen}
              />
            </div>
          </div>
            <div>
              <div></div>
            </div>
        </div>
      </StyledHeader>
      <MobileMenu
        open={open}
        setOpen={setOpen}
      />
    </>
  )
}
