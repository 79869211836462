import React, { Component } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import 'scss/main.scss'
import SEO from "components/Seo/Seo"
import GlobalStyles from "utils/GlobalStyles";
import theme from "utils/Theme"
import Header from "components/Header/Header"
import Footer from "components/Footer/Footer"
import NewHeader from "components/NewHeader/NewHeader"

const scrollToElement = require('scroll-to-element');

class Layout extends Component {
	componentDidMount() {
		this.dcfTracking()

		const hash = window.location.hash.split("?");

		if (hash[0] && hash[0].includes('#')) {
			setTimeout(() => {
				scrollToElement(hash[0]);
			}, 200)
		} else {
			scrollToElement('body', {
				duration: 200
			});
		}

		window.addEventListener('keydown', this.handleFirstTab);

		const header = document.getElementById("header");
        const siteWrap = document.getElementById("site-wrap");
        let headerHeight = header.clientHeight;
        siteWrap.style.paddingTop = `${headerHeight +  1}px`;
        window.addEventListener("resize", () => {
          headerHeight = header.clientHeight;
          siteWrap.style.paddingTop = `${headerHeight + 1}px`;
        });
	}

	handleFirstTab(e) {
		if (e.keyCode === 9) { // the "I am a keyboard user" key
			document.body.classList.add('user-is-tabbing');
			window.removeEventListener('keydown', this.handleFirstTab);
		}
	}

	dcfTracking() {
		if (typeof window !== 'undefined' && typeof window.__dataLayer !== 'undefined') {
			window.__dataLayer.trackPage()
			console.log('Tracking is set')
		}
	}

	render() {
		const {title, slug} = this.props
		return(
			<ThemeProvider theme={theme}>
					<NewHeader />
				<div className="wrapper" id="site-wrap">
					<SEO
						title={title}
						description={"The official website for Disney’s THE LION KING Education Programme, where you will find comprehensive resources for all age groups available to download and enjoy."}
						keywords={[`Lion King Education`, `Lion King`]}
						slug={slug}
					/>
					<GlobalStyles />
					<noscript className="no-js">Javascript is required to view the full experience of this site.</noscript>
					{/* <Header /> */}
					<main className="main">{this.props.children}</main>
					 <Footer />
				</div>
			</ThemeProvider>
		)
	}
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
	data: {
		metaDescription: "",
		metaTitle: "Lion King Education",
	},
};

export default Layout