import { Link } from 'gatsby'
import React, { useEffect } from 'react'

const Navigation = ({ setOpen, open, videoOpen, setVideoOpen }) => {
  const handleClick = () => {
    if (setOpen) {
      setOpen(false)
    }
  }

  const handleTrailerOpen = () => {
    if (videoOpen === true || videoOpen === false) {
      setVideoOpen(!videoOpen)
    }
  }
  return (
    <ul onClick={() => handleClick()}>
      <li>
        <Link to="/teacher-library/">
          <span className="sr-only">Teacher Library</span>
          Teacher Library
        </Link>
      </li>
      <li>
        <Link to="/video-hub/">
          <span className="sr-only">Video hub</span>
          Video hub
        </Link>
      </li>
      <li>
        <Link to="/circle-of-life-award/">
          <span className="sr-only">Circle of Life Award</span>
          Circle of Life Award
        </Link>
      </li>
      <li>
        <Link to="/workshops">
          <span className="sr-only">Workshops</span>
          Workshops
        </Link>
      </li>
      <li>
        <Link to="/faq">
          <span className="sr-only">FAQ</span>
          FAQ
        </Link>
      </li>
      <li>
        <Link to="/feedback">
          <span className="sr-only">Feedback</span>
          Feedback
        </Link>
      </li>
    </ul>
  )
}

export default Navigation
