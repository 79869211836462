import React, {Component} from "react"
import {Link} from 'gatsby'
import styled from "styled-components"
import {media} from "utils/Media"

const scrollToElement = require('scroll-to-element');


const Relative = styled.div`
    position: relative;
`

const HeaderContainer = styled.div`
    background: white;
    display: block;
    position: relative;
    top: 0;
    width: 100%;
    max-width: 1600px;
    padding: 0;
    transition: background .2s ease-in-out;
    z-index: 501;
    height: 65px;

    &.collapsed {
        top: 0;
        background: white;
        position: fixed;
    }
    &.active {
        //background: none !important;
    }
    
    .menu-ul {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        margin-left: 0;
        height: 65px;
        
        @media ${media.sm} {
            margin-left: 15px;
        }
        
        
        li {
            display: inline-block;
            position: relative;
            line-height: 65px;
            padding: 0 0.35rem;
            z-index: 9999;
            
            @media ${media.disney} {
                padding: 0 1rem;
            }
            
            &.nav-toggle-holder {
                @media ${media.disney} {
                    display: none;
                }
                
                .nav-toggle-helper {
                    // position: absolute;
                    // top: 50%;
                    // left: 0;
                    width: 40px;
                    height: 50px;
                    // transform: translateY(-50%);
                    display: flex;
                    justify-content: center;
                }
            }
            
            &.menu-element {
                display: none;
            }
            
            &.visible-sm {
                @media ${media.sm} {
                    display: inline-block;
                }
            }
            
            &.visible-md {
                @media ${media.md} {
                    display: inline-block;
                }
            }
            
            &.visible-lg {
                @media ${media.disney} {
                    display: inline-block;
                }
            }
            
             &.visible-xl {
                @media ${media.xl} {
                    display: inline-block;
                }
            }
            
            &.close > span, &.menu > span, .link {
                width: 100%;
                font-weight: normal;
                color: ${props => props.theme.colors.black};
                font-family: ${props => props.theme.font.family.medium};
                font-weight: 500;
                text-decoration: none;
                display: block;
                height: 100%;
                transition: all .2s ease-in-out;
                font-size: 1rem;
                line-height: auto;
                
                &:hover, &.active {
                    color: ${props => props.theme.colors.darkGrey};
                }

            }
            
            &.close {
                display: none;
            }
            
            &.menu {
                @media ${media.sm} {
                    display: none;
                }
            }

            &.close, &.menu {
                @media screen and (max-width: ${props => props.theme.sizes.sm}) {
                    padding: 0;
                    top: 3px;
                }
            }
        }
    }
    
    .tesSponsor {
        position: absolute;
        display: block;
        width: 100px;
        top: 22px;
        right: 15px;
        
        @media ${media.sm} {
            top: 16px;
            right: 15px;
            width: 130px;
        }
      
        @media ${media.md} {
            width: 160px;
            top: 10px;
            right: 15px;
        }
    }
`

const NavToggle = styled.button`
    position: relative;
    z-index: 501;
    background: none;
    height: 40px;
    width: 40px;
    border: 0;
    margin: 0;
    transition: all .2s ease-in-out;
    cursor: pointer;
    padding: 0;
    border: none;
`

const NavToggleBurger = styled.span`
    position: absolute;
    top: calc(50% - 2px);
    left: 6px;
    transform: translateY(-50%);
    width: calc(100% - 12px);
    height: 2px;
    border-radius: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    background-color: ${props => props.theme.colors.black};
    
    &:before,
    &:after {
        transition: all .2s ease-in-out;
        height: 2px;
        background-color: ${props => props.theme.colors.black};
        position: absolute;
        border-radius: 0;
        content: "";
        width: 100%;
        top: -9px;
        left: 0;
    }

    &:before {
    }

    &:after {
        top: 9px;
    }
    
    &.active {
        background-color: transparent;
        
        &:before{
            top: 0;
            transform: rotate(45deg)
        }
        
        &:after {
            top: 0;
            transform: rotate(-45deg)
        }
    }
`

const Nav = styled.nav`
    margin-top: 64px;
    transition: opacity .2s ease-in-out;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 500;
    width: 600px;
    max-width: initial;
    transform: translateX(-100%);
    opacity: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    
    ul {
        position: relative;
        z-index: 502;
        margin-top: 0;
        width: 100%;
        max-height: 100%;
        overflow: auto;
        margin-bottom: 0;
        text-align: center;

        @media screen and (max-width: ${props => props.theme.sizes.sm}) {
            padding-left: 0px;
        }

        li {
            display: block;
            margin: 1rem auto;
            min-height: 4rem;
            position: relative;
            width: 100%;

            .link {
                width: 100%;
                font-weight: normal;
                color: ${props => props.theme.colors.black};
                font-family: ${props => props.theme.font.family.medium};
                font-weight: 500;
                text-decoration: none;
                display: block;
                height: 100%;
                transition: all .2s ease-in-out;
                font-size: 1.75rem;
                line-height: auto;
                
                @media ${media.sm} {
                    font-size: 2rem;
                }

                &:hover, &.active {
                    color: ${props => props.theme.colors.darkGrey};
                }
            }
        }
    }

    &.active {
        transform: translateX(0);
        opacity: 1;
    }
`

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navToggled: false,
            collapsed: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true)
        window.addEventListener('resize', this.handleScroll, true)
        window.addEventListener('resize', this.toggleNavOnResize, true)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true)
        window.removeEventListener('resize', this.handleScroll, true)
        window.removeEventListener('resize', this.toggleNavOnResize, true)
    }

    handleScroll = () => {
        // let scrollPosition = document.documentElement.scrollTop
        let scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
        let collapsed = scrollPosition < 1 ? false : true
        this.setState({
            collapsed
        })
    }

    toggleNav = () => {
        this.setState({
            navToggled: !this.state.navToggled
        })
    }

    toggleNavOnResize = () => {
        const width = window.innerWidth;

        if (width > 1023) {
            this.setState({
                navToggled: false
            })
        }
    }

    scrollDown = () => {
        scrollToElement('#creative-team', {
            duration: 300
        })
        this.setState({
            navToggled: false
        })
    }

    closeNav = (e) => {
        if (e.which === 9) { // tab
            this.setState({
                navToggled: false
            })
        }
    }

    renderMobileNav = () => {

        return (

            <Nav id="navbarSupportedContent"
                role="navigation"
                className={`nav active collapsed`}>
                <ul className="nav__btns">
                    <li>
                        <Link to="/" className="link" activeClassName="active">
                            Home
                            </Link>
                    </li>
                    <li>
                        <Link to="/teacher-library" className="link" activeClassName="active">
                            Teacher Library
                            </Link>
                    </li>
                    <li>
                        <Link to="/video-hub" className="link" activeClassName="active">
                            Video Hub
                            </Link>
                    </li>
                    <li>
                        <Link to="/circle-of-life-award" className="link" activeClassName="active">
                            Circle of Life Award
                            </Link>
                    </li>
                    <li>
                        <Link to="/workshops" className="link" activeClassName="active">
                            Workshops
                            </Link>
                    </li>
                    <li>
                        <Link to="/faq" className="link" activeClassName="active">
                            FAQ
                            </Link>
                    </li>
                    <li onKeyDown={this.closeNav}>
                        <Link to="/feedback" className="link" activeClassName="active">
                            Feedback
                            </Link>
                    </li>
                </ul>
            </Nav>

        )

    }

    render() {
        const {navToggled, collapsed} = this.state

        return (
            <>
                <HeaderContainer className={`${navToggled ? 'active' : ''} ${collapsed ? 'collapsed' : ''}`}>
                    <Relative>
                        <ul className="menu-ul">
                            <li className={`menu-element visible-sm ${navToggled && 'd-none'}`}>
                                <Link to="/" className="link" activeClassName="active">
                                    Home
                                </Link>
                            </li>
                            <li className={`menu-element visible-sm ${navToggled && 'd-none'}`}>
                                <Link to="/teacher-library" className="link" activeClassName="active">
                                    Teacher Library
                                </Link>
                            </li>
                            <li className={`menu-element visible-sm ${navToggled && 'd-none'}`}>
                                <Link to="/video-hub" className="link" activeClassName="active">
                                    Video Hub
                                </Link>
                            </li>
                            <li className={`menu-element visible-md ${navToggled && 'd-none'}`}>
                                <Link to="/circle-of-life-award" className="link" activeClassName="active">
                                    Circle of Life Award
                                </Link>
                            </li>
                            <li className={`menu-element visible-lg ${navToggled && 'd-none'}`}>
                                <Link to="/workshops" className="link" activeClassName="active">
                                    Workshops
                                </Link>
                            </li>
                            <li className={`menu-element visible-lg ${navToggled && 'd-none'}`}>
                                <Link to="/faq" className="link" activeClassName="active">
                                    FAQ
                                </Link>
                            </li>
                            <li className={`menu-element visible-xl ${navToggled && 'd-none'}`} onKeyDown={this.closeNav}>
                                <Link to="/feedback" className="link" activeClassName="active">
                                    Feedback
                                </Link>
                            </li>

                            
                            
                            <li className="nav-toggle-holder">
                                <div className="nav-toggle-helper">
                                    <NavToggle
                                        className={`${navToggled ? 'active' : ''} ${collapsed ? 'collapsed' : ''}`}
                                        onClick={this.toggleNav}
                                        aria-expanded={navToggled ? true : false}
                                        aria-controls="navbarSupportedContent"
                                        type="button"
                                        aria-label="Toggle navigation"
                                    >
                                        <NavToggleBurger
                                            className={`${navToggled ? 'active' : ''} ${collapsed ? 'collapsed' : ''}`}/>
                                    </NavToggle>
                                </div>
                            </li>
                            <li className={`close ${navToggled && 'd-inline-block'}`}>
                                <span style={{ cursor: 'pointer' }} onClick={this.toggleNav}>Close</span>
                            </li>
                            <li className={`menu ${navToggled && 'd-none'}`}>
                                <span onClick={this.toggleNav}>Menu</span>
                            </li>
                        </ul>
                    </Relative>
                </HeaderContainer>
                {navToggled ? this.renderMobileNav() : false }
            </>
        )
    }
}

export default Header